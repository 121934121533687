<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <ag-table :columns="columns" :rows="stations" :loading="loading" link="station" />
    </v-container>
</template>

<script>
import axios from 'axios';
import AgTable from '@/components/AgTable.vue';

export default {
    name: 'StationList',

    components: {
        AgTable
    },

    data() {
        return {
            loading: true,
            stations: [],
            columns: [
                { field: 'id', headerName: 'ID', sortable: true, resizable: true, width: 90 },
                { field: 'aemoId', headerName: 'AEMO ID', sortable: true, resizable: true, filter: true },
                { field: 'name', sortable: true, resizable: true, filter: true },
                { field: 'bidder.name', headerName: 'Bidder', sortable: true, resizable: true, filter: true },
                { field: 'owner.name', headerName: 'Owner', sortable: true, resizable: true, filter: true },
                { field: 'trader.name', headerName: 'Trader', sortable: true, resizable: true, filter: true },
                { field: 'tnspZone.name', headerName: 'Zone', sortable: true, resizable: true, filter: true },
                { field: 'node.name', headerName: 'Node', sortable: true, resizable: true, filter: true }
            ]
        };
    },

    async created() {
        this.loading = true;
        const response = await axios.get('/api/stations');
        this.stations = response.data;
        this.loading = false;
    }
};
</script>
