<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <div class="toolbar">
            <div>
                <v-toolbar dense>
                    <v-btn v-if="allowAdd" @click="add" class="primary mr-2">
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                    <v-text-field v-model="search" full-width hide-details prepend-icon="mdi-magnify" single-line clearable></v-text-field>
                    <v-spacer />
                    <v-progress-linear v-if="loading" loading indeterminate />
                </v-toolbar>
            </div>
            <div class="grid-wrapper">
                <ag-grid-vue
                    :class="$vuetify.theme.dark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'"
                    class="ag-grid-vue"
                    :columnDefs="columns"
                    :rowData="rows"
                    @row-clicked="onRowClicked"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </div>
        </div>
    </v-container>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
    name: 'AgTable',

    props: ['columns', 'rows', 'loading', 'link', 'allowAdd'],

    components: {
        AgGridVue
    },

    data() {
        return {
            rowData: null,
            gridApi: null,
            search: ''
        };
    },

    watch: {
        search: function (value) {
            this.gridApi.setQuickFilter(value);
        }
    },

    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
        },
        onRowClicked(params) {
            this.$router.push({ name: this.link, params: { id: params.data.id } });
        },
        sizeToFit() {
            this.gridApi.sizeColumnsToFit();
        },
        add() {
            this.$emit('add');
        }
    }
};
</script>

<style scoped>
.toolbar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.grid-wrapper {
    flex: 1 1 0px;
    width: 100%;
}

.ag-grid-vue {
    height: 100%;
    width: 100%;
}
</style>
